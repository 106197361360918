import { Login } from '../components/Login';
import React from 'react'

const Dashboard = () => {
    return (
        <>
            <Login />
        </>
    )
}

export default Dashboard